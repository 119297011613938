









































































import { AssetsEnum } from "@/api/models/utils/assetsEnum";
import { errorAlert } from "@/libs/sweetAlerts/alerts";
import { Component, Vue } from "vue-property-decorator";
import { BImg } from 'bootstrap-vue';

@Component({
  components: {
      BImg
  },
})
export default class Document extends Vue{
  get imgAdmin() {
    return require('/src/assets/images/illustration/email.svg')
  }
  get imgUtilisation() {
    return require('/src/assets/images/illustration/marketing.svg')
  }
  get imgInformationInterlocuteurs() {
    return require('/src/assets/images/illustration/api.svg')
  }
  async downloadUtilisation() {
    // await this.$http.ressifnet.utils
    //   .downloadAsset(AssetsEnum.NOTICE_UTILISATION)
    //   .then(
    //     async (response: any) => {
    //       window.open(response);
    //     },
    //     (error: any) => {
    //       if (error?.response?.data?.errors) {
    //         errorAlert.fire({
    //           text: error.response.data.errors[
    //             Object.keys(error.response.data.errors)[0]
    //           ],
    //         });
    //       } else {
    //         errorAlert.fire({
    //           text: error.message,
    //         });
    //       }
    //     }
    //   );
  }

  async downloadNoticeImport() {
    await this.$http.ressifnet.utils
      .downloadAsset(AssetsEnum.DOCUMENTATION_IMPORT_DOSSIERS)
      .then(
        async (response: any) => {
          window.open(response);
        },
        (error: any) => {
          if (error?.response?.data?.errors) {
            errorAlert.fire({
              text: error.response.data.errors[
                Object.keys(error.response.data.errors)[0]
              ],
            });
          } else {
            errorAlert.fire({
              text: error.message,
            });
          }
        }
      );
  }
  
  async downloadAdmin() {
    // await this.$http.ressifnet.utils
    //   .downloadAsset(AssetsEnum.NOTICE_ADMINISTRATION)
    //   .then(
    //     async (response: any) => {
    //       window.open(response);
    //     },
    //     (error: any) => {
    //       if (error?.response?.data?.errors) {
    //         errorAlert.fire({
    //           text: error.response.data.errors[
    //             Object.keys(error.response.data.errors)[0]
    //           ],
    //         });
    //       } else {
    //         errorAlert.fire({
    //           text: error.message,
    //         });
    //       }
    //     }
    //   );
  }
  
  async downloadNoticeStat() {
    // await this.$http.ressifnet.utils
    //   .downloadAsset(AssetsEnum.NOTICE_STATISTIQUES)
    //   .then(
    //     async (response: any) => {
    //       window.open(response);
    //     },
    //     (error: any) => {
    //       if (error?.response?.data?.errors) {
    //         errorAlert.fire({
    //           text: error.response.data.errors[
    //             Object.keys(error.response.data.errors)[0]
    //           ],
    //         });
    //       } else {
    //         errorAlert.fire({
    //           text: error.message,
    //         });
    //       }
    //     }
      // );

  }
  async downloadRGPD() {
    // await this.$http.ressifnet.utils
    //   .downloadAsset(AssetsEnum.DOCUMENTS_RGPD)
    //   .then(
    //     async (response: any) => {
    //       window.open(response);
    //     },
    //     (error: any) => {
    //       if (error?.response?.data?.errors) {
    //         errorAlert.fire({
    //           text: error.response.data.errors[
    //             Object.keys(error.response.data.errors)[0]
    //           ],
    //         });
    //       } else {
    //         errorAlert.fire({
    //           text: error.message,
    //         });
    //       }
    //     }
    //   );
  }
}
